import React from 'react'

const Dashboard = () => {
    return (
        <div className={'Dashboard'} >
            <div className="container">
                <div className="platform">
                    <div className="message">
                        <h1>I have No idea what to put here</h1>
                        <h2>So im leaving it for when i get inspiration 🌚🌚🌚</h2>                    
                    </div>

                </div>
            </div>
        </div>
    );
}


export default Dashboard